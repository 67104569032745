import React from "react";
import PropertySelector from "./PropertySelector";
import ServiceAccountNavigator from "./ServiceAccountNavigator";
import Header from "./Header";
import Footer from "./Footer";

class Layout extends React.Component {
  render() {
    return (
      <div className="members-layout">
        {this.props.properties.length > 1 ? (
          <PropertySelector setProperty={this.props.setProperty} properties={this.props.properties} />
        ) : null}
        {
          this.props.serviceAccount ? <ServiceAccountNavigator properties={this.props.properties}/> : null
        }
        <div className="page-layout">
          <div>
            <Header />
            <div>{this.props.children}</div>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}

export default Layout;
