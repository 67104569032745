import React from "react";
import Modal from "../generics/Modal";
import Card from "../generics/Card";
import moment from "moment";
import RowStat from "../generics/RowStat";
import Loader from "../generics/Loader";
import ReactGA from "react-ga";
import GenericMessage from "./GenericMessage";

class SubmitReadingModal extends React.Component {
    constructor() {
        super();
        this.state = {
            view: "electric",
            loading: true,
            readings: null,
            submittables: {
                gas: null,
                electric: null,
            },
            successful: false,
            error: false,
            pendingRegistration: false
        }
    }
    async componentDidMount() {
        const readings = await this.props.api.get(
            `/properties/${this.props.property.id}/readings`
        );
        this.setState({
            readings: readings.data.data,
            view: !readings.data.data.electric.length ? "gas" : "electric",
            loading: false
        });
    }
    retrieveLatestReading(readingArr) {
        readingArr = readingArr.length
            ? readingArr.filter(
                (x) =>
                    x.typeDescription === "Customer" || x.typeDescription === "Actual"
            )
            : [];
        return readingArr.length
            ? readingArr
                .map((x) => ({ ...x, date: moment(x.date) }))
                .sort((a, b) => b.date - a.date)[0]
            : {
                value: null,
                date: null,
            };
    }
    handleExit = e => {
        if (!e) {
            this.props.onExit(false);
        } else if (e.target.className.indexOf("exitable") > -1) {
            this.props.onExit(false);
        }
    }
    submitReadings = async () => {
        if (this.state.submittables.gas || this.state.submittables.electric) {
            ReactGA.event({
                category: 'User',
                action: `Submitted Readings`
              });
            this.setState(
                {
                    loading: true,
                },
                async () => {
                    if (this.state.submittables.gas) {
                        const keys = Object.keys(this.state.submittables.gas);
                        try {
                            const res = await Promise.all(keys.map(x => this.props.api.post(
                                `/properties/${this.props.property.id}/readings`,
                                {
                                    type: "gas",
                                    reading: {
                                        serial: x,
                                        mprn: this.state.readings.gas[0].mprn,
                                        value: this.state.submittables.gas[x],
                                        date: new Date(),
                                    },
                                }
                            )));
                            if (res.findIndex(x => !x.data.success) === -1) {
                                this.setState({
                                    loading: false,
                                    successful: true,
                                    error: false
                                });
                            } else {
                                throw res;
                            }
                        } catch (e) {
                            console.log(e);
                            this.setState({
                                loading: false,
                                error: true,
                                successful: false
                            });
                        }
                    }
                    if (this.state.submittables.electric) {
                        const keys = Object.keys(this.state.submittables.electric);
                        try {
                            const res = await this.props.api.post(
                                `/properties/${this.props.property.id}/readings`,
                                {
                                    type: "electric",
                                    reading: {
                                        mpan: this.state.readings.electric[0].mpan,
                                        serial: this.state.readings.electric[0].meters[0].serial,
                                        date: new Date(),
                                        registers: keys.map(registerId => ({
                                            registerId,
                                            value: this.state.submittables.electric[registerId]
                                        }))
                                    },
                                }
                            );
                            if (res.data.success) {
                                this.setState({
                                    loading: false,
                                    successful: true,
                                    error: false
                                });
                            } else {
                                throw res;
                            }
                        } catch (e) {
                            this.setState({
                                loading: false,
                                error: true,
                                successful: false
                            });
                        }
                    }
                }
            );
        }
    };
    render() {
        return (this.state.pendingRegistration ? <GenericMessage title="No meters found" subTitle={`We can't find any meters on your account.`} body="If you're in the process of joining Green this is normal and as the switch completes you will be able to access this page." cancelText="Close" onExit={() => this.handleExit()} /> :
            <Modal><div
                className="exitable animated duration-2 fadeIn"
                onClick={() => this.handleExit}
            >
                <Card style={{ width: this.state.loading ? null : 500 }}>
                    {
                        this.state.loading ? <div className="padding-30"><Loader /></div> :
                            <div className="col-1">
                                <div className="col-1 gap-5 padding-30 bg-green">
                                    <h1 className="ui-title-three colour-white">Submit meter reading</h1>
                                    <h2 className="ui-title-five ui-title-sub colour-white">
                                        Keep your bills accurate
                                    </h2>
                                </div>
                                <div>
                                    {this.state.readings.gas.length && this.state.readings.electric.length ? <div className="padding-20">
                                        <select className="ui-select" onChange={e => this.setState({ view: e.currentTarget.value, successful: false, error: false, submittables: {
                                            gas: null,
                                            electric: null
                                        } })}>
                                            <option value="electric">Electric</option>
                                            <option value="gas">Gas</option>
                                        </select>
                                    </div> : null}
                                    {
                                        this.state.readings[this.state.view].length && this.state.readings[this.state.view][0].meters.length ? this.state.readings[this.state.view][0].meters.map((x) => (
                                            <><RowStat
                                                basic
                                                stat={x.serial}
                                                title="Meter serial no."
                                                style={{ borderTop: "1px solid var(--ui-border)" }}
                                            />
                                                <div className="padding-20 col-1 gap-30">
                                                    {!x.registers ? <>
                                                        <div className="col-1 gap-10">
                                                            <input className="ui-input" type="number" placeholder={`Enter reading`} onChange={(e) =>
                                                                this.setState({
                                                                    submittables: {
                                                                        gas: {
                                                                            ...this.state.submittables.gas,
                                                                            [x.serial]: parseFloat(e.currentTarget.value),
                                                                        },
                                                                        electric: this.state.submittables
                                                                            .electric,
                                                                    },
                                                                })
                                                            }
                                                                value={
                                                                    this.state.submittables.gas
                                                                        ? this.state.submittables.gas[x.serial]
                                                                            ? String(
                                                                                this.state.submittables.gas[
                                                                                x.serial
                                                                                ]
                                                                            )
                                                                            : ""
                                                                        : ""
                                                                } />
                                                            {this.retrieveLatestReading(x.readings).date ? <p className="ui-disclaimer">Last reading: {this.retrieveLatestReading(x.readings).value} ({this.retrieveLatestReading(
                                                                x.readings
                                                            ).date.format("Do MMM YYYY")})</p> : null}
                                                        </div>
                                                    </> : x.registers.map((y, i) => (
                                                        <div className="col-1 gap-10">
                                                            {x.registers.length > 1 ? <h5 className="ui-label">{y.bandDescription}</h5> : null}
                                                            <input className="ui-input" type="number" placeholder={`Enter reading`} onChange={(e) =>
                                                                this.setState({
                                                                    submittables: {
                                                                        gas: this.state.submittables.gas,
                                                                        electric: {
                                                                            ...this.state.submittables
                                                                                .electric,
                                                                            [y.registerId]: parseFloat(e.currentTarget.value),
                                                                        },
                                                                    },
                                                                })
                                                            }
                                                                value={
                                                                    this.state.submittables.electric
                                                                        ? this.state.submittables.electric[
                                                                            y.registerId
                                                                        ]
                                                                            ? String(
                                                                                this.state.submittables
                                                                                    .electric[y.registerId]
                                                                            )
                                                                            : ""
                                                                        : ""
                                                                } />
                                                                {this.retrieveLatestReading(y.readings).date ? <p className="ui-disclaimer">Last reading: {this.retrieveLatestReading(y.readings).value} ({this.retrieveLatestReading(
                                                                y.readings
                                                            ).date.format("Do MMM YYYY")})</p> : null}
                                                        </div>
                                                    ))}
                                                </div>
                                            </>
                                        )) : null
                                    }
                                    {
                                        this.state.readings.electric.length && this.state.readings.electric[0].meters.length ?
                                            <div className="padding-bottom-20 padding-left-20 padding-right-20">
                                                <button className="ui-btn-secondary ui-btn-flat" onClick={() => this.submitReadings()} disabled={this.state.successful}>{this.state.successful ? "Successfully submitted" : "Submit your reading"}</button>
                                                <button className="ui-btn-basic ui-btn-flat margin-left-10" onClick={() => this.handleExit()}>Cancel</button>
                                                {
                                                    this.state.error && <p className="ui-error">Something went wrong, we haven't submitted your meter readings. Note that we only accept one reading per fuel every 24 hours.</p>
                                                }
                                                {
                                                    this.state.successful && <p className="ui-success">Successfully submitted, we have received your meter readings.</p>
                                                }
                                            </div> : null
                                    }
                                </div>
                            </div>
                    }
                </Card>
            </div>
            </Modal>
        );
    }
}

export default SubmitReadingModal;
