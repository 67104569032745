import React from "react";
import Modal from "../generics/Modal";
import Card from "../generics/Card";

class GenericMessage extends React.Component {
  handleExit = e => {
    if (e.target.className.indexOf("exitable") > -1) {
      this.props.onExit(false);
    }
  }
  render() {
    return (
      <Modal>
        <div
          className="exitable animated duration-2 fadeIn"
          onClick={() => this.handleExit}
        >
          <Card style={{width: 500}}>
            <div className="col-1">
              <div className="col-1 gap-5 padding-30 bg-green">
                <h1 className="ui-title-three colour-white">{this.props.title}</h1>
                <h2 className="ui-title-five ui-title-sub colour-white">
                  {this.props.subTitle}
                </h2>
              </div>
              <div className="padding-30 padding-top-40">
                <p className="ui-body margin-bottom-20">{this.props.body}</p>
                <div>
                  {
                    this.props.confirmButton ? <button
                      type="button"
                      className="ui-btn-secondary ui-btn-flat margin-top-20 margin-right-20"
                      onClick={() => this.props.onConfirm(false)}
                    >
                      {this.props.confirmText ? this.props.confirmText : "Confirm"}
                    </button> : null
                  }
                  <button
                    type="button"
                    className="ui-btn-basic  ui-btn-flat margin-top-20"
                    onClick={() => this.props.onExit(false)}
                  >
                    {this.props.cancelText ? this.props.cancelText : "Cancel"}
                  </button>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </Modal>
    );
  }
}

export default GenericMessage;
