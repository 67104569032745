import React from "react";
import Modal from "../generics/Modal";
import Card from "../generics/Card";
import TopUp from "../payments/TopUp";
import RowStat from "../generics/RowStat";
import GenericMessage from "./GenericMessage";
import Loader from "../generics/Loader";
import ReactGA from "react-ga";

class TopUpModal extends React.Component {
    constructor() {
        super();
        this.state = {
            amount: 10,
            balance: 0,
            loading: false,
            successModal: false
        }
    }
    async componentDidMount() {
        // const balance = await this.props.api.get(`/properties/${this.props.property.id}/balance`);
        // this.setState({
        //     balance: parseFloat(balance.data.data),
        //     loading: false
        // });
    }
    handleExit = e => {
        if (!e) {
            this.props.onExit(false);
        } else if (e.target.className.indexOf("exitable") > -1) {
            this.props.onExit(false);
        }
    }
    render() {
        return (
            this.state.successModal ? <GenericMessage title="Top-up successful" subTitle={`Your payment of £${this.state.amount} has been taken.`} body="Your account will reflect the top-up within the next 72 hours." cancelText="Close" onExit={() => this.handleExit()} /> : <Modal><div
                className="exitable animated duration-2 fadeIn"
                onClick={() => this.handleExit}
            >
                <Card>
                    {this.state.loading ? <div className="padding-30"><Loader /></div> :
                        <div className="col-1">
                            <div className="col-1 gap-5 padding-30 bg-green">
                                <h1 className="ui-title-three colour-white">Top-up your account</h1>
                                <h2 className="ui-title-five ui-title-sub colour-white">
                                    Keep your balance in credit
                                    </h2>
                            </div>
                            {/* <div>
                                <RowStat
                                    stat={this.state.loading ? "" : `£${this.state.balance}`}
                                    statColour={this.state.balance >= 0 ? "green" : "orange"}
                                    title="Current Balance"
                                />
                                <RowStat
                                    stat={this.state.loading ? "" : `£${(this.state.balance + this.state.amount).toFixed(2)}`}
                                    statColour={this.state.balance + this.state.amount >= 0 ? "green" : "orange"}
                                    title="New Balance"
                                />
                            </div> */}
                            <div className="padding-20 col-1 gap-20">
                                <div className="col-5 col-nr gap-5 padding-top-10 padding-bottom-10">
                                    {
                                        [10, 25, 50, 100, 200].map(x => <button onClick={() => this.setState({ amount: x })} className={`ui-btn-${this.state.amount === x ? "primary" : "basic"} ui-btn-flat ui-btn-compact`}>£{x}</button>)
                                    }
                                </div>
                                <div className="ui-icon-input">
                                    <p className="ui-body">
                                        &pound;
                                </p>
                                    <input className="ui-input ui-input-compact" type="number" pattern="^\d*(\.\d{0,2})?$" step="0.01" min="1" max="2500" placeholder="Top-up amount" value={this.state.amount === 0 ? "" : this.state.amount} onChange={e => {
                                        let amount = parseFloat(e.currentTarget.value);
                                        if (amount > 2500) amount = 2500;
                                        if (amount < 1 || isNaN(amount)) amount = 1;
                                        this.setState({
                                            amount: e.currentTarget.value ? parseFloat(amount.toFixed(2)) : 0
                                        })
                                    }} />
                                </div>
                                <TopUp api={this.props.api} amount={this.state.amount} property={this.props.property} onSuccess={() => {
                                    ReactGA.event({
                                        category: 'User',
                                        action: `Top Up Success`
                                    });
                                    this.setState({ successModal: true });
                                }} />
                                <button
                                    type="button"
                                    className="ui-btn-basic ui-btn-compact ui-btn-flat"
                                    onClick={() => this.props.onExit(false)}
                                >
                                    {this.props.cancelText ? this.props.cancelText : "Cancel"}
                                </button>
                            </div>
                        </div>
                    }
                </Card>
            </div>
            </Modal>
        );
    }
}

export default TopUpModal;
