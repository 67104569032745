import axios from "axios";

export default token => {
  return axios.create({
    // baseURL: "http://localhost:9999/",
    baseURL: "https://api.core.green.energy/",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  });
};
