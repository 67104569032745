import React from "react";
import Card from "./Card";

const CardButton = (props) => (
    <Card className={`col-1 grid-center-center text-center padding-40 ui-card-button ${props.disabled ? "ui-card-button-disabled" : null}`} onClick={() => props.disabled ? null : props.onClick()}>
        {props.icon ? <i className={`colour-${props.iconColour} fa fa-${props.icon} margin-bottom-10`} style={{fontSize:24}}/> : null}
        <p className="ui-title-four">{props.title}</p>
        <p className="ui-title-sub margin-top-5">{props.subTitle}</p>
    </Card>
);

export default CardButton;
