import React from "react";
import {Helmet} from "react-helmet";
import moment from "moment";
import CardButton from "../../components/generics/CardButton";

export default class extends React.Component {
  constructor() {
    super();
    this.state = {
      phoneClosed: moment().isAfter(22, "hours") && moment().isBefore(7, "hours")
    }
  }
  render() {
    return <div>
      <Helmet>
            <title>Support - Green Members</title>
        </Helmet>
      <div className="banner-compact">
        <h1 className="ui-title-mega colour-white">Help &amp; Support</h1>
        <h2 className="ui-title-four ui-title-sub colour-white margin-top-10 opacity-60">
          We are always here to assist you as a member of Green.
      </h2>
      </div>
      <div className="col-1 padding-50 gap-80">
      <h3 className="ui-title-two text-center padding-top-30">Contact Channels</h3>
        <div className="col-3 gap-40">
          <CardButton icon="comments" title="Live Chat" subTitle="We're always here" iconColour="green" onClick={() => window.Intercom("show")} />
          <CardButton disabled={this.state.phoneClosed} icon="phone fa-rotate-90" title={this.state.phoneClosed ? "Open at 7am" : "Call us"} subTitle="0345 222 2525" iconColour="olive" onClick={() => window.location.href = "tel:03452222525"} />
          <CardButton icon="envelope" title="Email us" subTitle="support@green.energy" iconColour="blue" onClick={() => window.location.href = "mailto:support@green.energy"} />
        </div>
        <h3 className="ui-title-two text-center padding-top-30">Helpful Links</h3>
        <div className="col-3 gap-40">
        <CardButton icon="question-circle" title="Help Centre" subTitle="FAQs and more" iconColour="olive" onClick={() => window.open("https://help.green.energy/en/", "_blank")} />
          <CardButton icon="info-circle" title="Energy Saving Tips" subTitle="See how you could save" iconColour="purple" onClick={() => window.open("https://help.green.energy/en/collections/1635680-energy-saving-tips", "_blank")} />
          <CardButton icon="star" title="Leave a Review" subTitle="Visit our Trustpilot" iconColour="green" onClick={() => window.open("https://www.trustpilot.com/evaluate/green.energy", "_blank")} />
          <CardButton icon="bolt" title="Power Cut?" subTitle="Call 105" iconColour="blue" onClick={() => window.location.href = "tel:105"} />
          <CardButton icon="fire" title="Smell Gas?" subTitle="Call 0800 111 999" iconColour="orange" onClick={() => window.location.href = "tel:0800111999"} />
          <CardButton icon="exclamation-triangle" title="Filing a Complaint" subTitle="complaints@green.energy" iconColour="grey" onClick={() => window.location.href = "mailto:complaints@green.energy"} />
        </div>
      </div>
    </div>;
  }
}