import React from "react";
import moment from "moment";
import { Helmet } from "react-helmet";
import Stat from "../../components/generics/Stat";
import Loader from "../../components/generics/Loader";
import TopUpModal from "../../components/modals/TopUp";
import BillModal from "../../components/modals/Bill";
import Table from "../../components/generics/Table";

export default class extends React.Component {
  constructor() {
    super();
    this.state = {
      topUpModal: false,
      billModal: false,
      bills: [],
      payments: [],
      balance: null,
      plan: null,
      loading: true,
      billId: null
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.property.id !== this.props.property.id) {
      this.setState({ loading: true }, () => this.getData());
    }
  }
  async componentDidMount() {
    this.getData();
  }
  async getData() {
    const res = await this.props.api.get(`/properties/${this.props.property.id}/invoices`);
    this.setState({
      loading: false,
      bills: res.data.data
    });
    // const results = await Promise.all([
    //   await this.props.api.get(`/properties/${this.props.property.id}/balance`),
    //   await this.props.api.get(
    //     `/properties/${this.props.property.id}/invoices`
    //   ),
    //   await this.props.api.get(`/properties/${this.props.property.id}/plan`),
    //   await this.props.api.get(
    //     `/properties/${this.props.property.id}/payments`
    //   ),
    //   await this.props.api.get(`/properties/${this.props.property.id}`)
    // ]);
    // this.setState({
    //   balance: results[0].data.data,
    //   bills: results[1].data.data.reverse(),
    //   paymentPlan: results[2].data.data,
    //   payments: results[3].data.data,
    //   nextBillDate: results[4].data.data.electric
    //     ? results[4].data.data.electric.meters.find(
    //       x => x.regiDescription === "Registered"
    //     )
    //       ? moment(
    //         results[4].data.data.electric.meters.find(
    //           x => x.regiDescription === "Registered"
    //         ).regiDate
    //       ).set("month", moment().format("M") - 1)
    //       : null
    //     : results[4].data.data.gas
    //       ? results[4].data.data.gas.meters.find(
    //         x => x.regiDescription === "Registered"
    //       )
    //         ? moment(
    //           results[4].data.data.gas.meters.find(
    //             x => x.regiDescription === "Registered"
    //           ).regiDate
    //         ).set("month", moment().format("M") - 1)
    //         : null
    //       : null,
    //   loading: false
    // });
  }
  render() {
    return <div>
      <Helmet>
        <title>Your Bills - Green Members</title>
      </Helmet>
      {this.state.topUpModal ? <TopUpModal api={this.props.api} property={this.props.property} onExit={() => this.setState({ topUpModal: false })} /> : null}
      {this.state.billModal ? <BillModal billId={this.state.billId} api={this.props.api} property={this.props.property} onExit={() => this.setState({ billModal: false })} /> : null}
      <div className="banner-compact">
        <h1 className="ui-title-mega colour-white">Your Bills</h1>
        <h2 className="ui-title-four ui-title-sub colour-white margin-top-10 opacity-60">
          View all bills for your account.
      </h2>
        <div className="banner-buttons">
          <button className="ui-btn-secondary margin-top-40" onClick={() => this.setState({ topUpModal: true })}>Top up</button>
          {/* <button className="ui-btn margin-top-40 margin-left-20" onClick={() => this.state.bills.length ? this.setState({ billModal: true, billId: this.state.bills[0].id }) : null}>View latest bill</button> */}
        </div>
      </div>
      <div className="padding-50">
        {this.state.loading ? (
          <div className="padding-50 grid-center-center">
            <Loader />
          </div>
        ) : (<>
          {/* <div className="col-4">
            <Stat
              stat={`£${this.state.balance}`}
              statColour={this.state.balance >= 0 ? "green" : "orange"}
              title="Your Balance"
              subTitle={`You’re in ${
                this.state.balance >= 0 ? "credit" : "debit"
                }`}
              onClick={() => this.setState({
                topUpModal: true
              })}
            />
            <Stat
              stat={
                this.state.bills[0]
                  ? `£${this.state.bills[0].cost.total.toFixed(2)}`
                  : "N/A"
              }
              statColour="green"
              title="Latest Bill"
              subTitle={
                this.state.bills[0]
                  ? moment(this.state.bills[0].date).format(
                    "Do MMMM YYYY"
                  )
                  : "No bills yet"
              }
              onClick={() => this.state.bills.length ? this.setState({ billModal: true, billId: this.state.bills[0].id }) : null}
            />
            <Stat
              stat={this.state.nextBillDate ? this.state.nextBillDate.format("Do MMM") : "N/A"}
              statColour={"blue"}
              title="Next Bill Date"
              subTitle={`When you're next due`}
            />
            <Stat
              stat={
                this.state.paymentPlan
                  ? `£${this.state.paymentPlan.amount.toFixed(2)}`
                  : "N/A"
              }
              statColour="blue"
              title="Direct Debit"
              subTitle={
                this.state.paymentPlan
                  ? `${this.state.paymentPlan.collectionDate}${this.state.paymentPlan.collectionDate === 1 ? "st" : this.state.paymentPlan.collectionDate === 2 ? "nd" : this.state.paymentPlan.collectionDate === 3 ? "rd" : "th"} of each month`
                  : "No plan set up"
              }
            />
          </div> */}
          <div className="container-small">
            <div className="col-1 gap-80 col-start-2">
              <div />
              <div>
                <h2 className="ui-title-one text-center">Your Bills</h2>
                <h3 className="ui-title-four ui-title-sub text-center margin-top-10">Latest bills which we have generated based on your usage.</h3>
              </div>
              <div>
                {!this.state.bills.length ? <p className="ui-label text-center">No bills found on your account.</p> : <Table data={this.state.bills.sort((a,b) => moment(b.date).valueOf() - moment(a.date).valueOf()).map((x,i) => ({
                  Reference: `#${x.id}`,
                  Date: moment(x.date).format("DD/MM/YYYY"),
                  Total: <>
                    {/* <i className={`fa fa-${this.state.bills.length - 1 === i ? "chevron-right colour-grey" : this.state.bills[i+1].cost.total > x.cost.total ? "chevron-down colour-green" : "chevron-up colour-orange"}`} style={{ marginRight: 10}}></i> */}
                    £{x.cost.total.toFixed(2)}
                  </>,
                  "": <span className="ui-link">View PDF</span>
                }))} onClick={async i => {
                  const res = await this.props.api.get(`/properties/${this.props.property.id}/invoices/${this.state.bills[i].id}`);
                  window.open(res.data.data, "_blank");
                }} />
              }
              </div>
              <div />
              {/* <div>
                <h2 className="ui-title-one text-center">Your Payments</h2>
                <h3 className="ui-title-four ui-title-sub text-center margin-top-10">Latest activity via direct debit, refund or top-ups</h3>
              </div>
              <div>
              {!this.state.payments.length ? <p className="ui-label text-center">No payments found on your account.</p> :  <Table data={this.state.payments.map(x => ({
                  Type: <>
                    <i style={{ marginRight: 10, color: x.type === "Account Topup" ? "#4C494B" : "#A0CC3A" }} className={`fa fa-${x.type === "Account Topup"
                      ? "coins"
                      : "credit-card-blank"}`} />
                    {x.type}
                  </>,
                  Date: moment(x.date).format("DD/MM/YYYY"),
                  Amount: `£${x.amount.toFixed(2)}`
                }))} />}
              </div> */}
              <div />
            </div>
          </div></>)}
      </div>
    </div>;
  }
}