import React from "react";
import { Helmet } from "react-helmet";
import Card from "../components/generics/Card";
import Axios from "../../Axios";

export default class extends React.Component {
  constructor(){
    super();
    this.state = {
      accountNumber: "",
      invalidAccountNumber: false
    }
  }
  viewAccount = async e => {
    e.preventDefault();
    try {
      const res = await Axios(localStorage.green_member_key).get(`properties/${this.state.accountNumber}`);
      if(res.data.success){
        localStorage.green_property_id = this.state.accountNumber;
        this.props.history.push("/members");
      } else {
        this.setState({invalidAccountNumber: true});
      }
    } catch (e) {
      this.setState({invalidAccountNumber: true});
    }
  }
  render() {
    return (
      <div className="col-1 sign-in">
        <Helmet>
          <title>Account Explorer - Green Members</title>
        </Helmet>
        <Card
          className="padding-50 padding-top-60 padding-bottom-60 gap-50 margin-40"
        >
          <div>
            <h1 className="ui-title-one">
              View an account.
                </h1>
            <h2 className="ui-title-four ui-title-sub margin-top-10">
              Please enter the account number below.
                </h2>
          </div>
          <div>
            <form className="ui-input-group" onSubmit={this.viewAccount}>
              <input
                className="ui-input"
                type="number"
                required
                placeholder="Account number"
                onChange={(e) => {
                  this.setState({
                    accountNumber: e.currentTarget.value
                  })
                }}
              />
              <button className="ui-btn ui-btn-primary">View</button>
            </form>
            {this.state.invalidAccountNumber ? (
              <p className="ui-label colour-orange margin-top-15">
                You don't have access to that account 😕
              </p>
            ) : null}
          </div>
        </Card>
      </div>
    );
  }
}
