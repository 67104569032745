import React from "react";
import { Redirect } from "react-router-dom";
import ReactGA from "react-ga";

import Loader from "../components/generics/Loader";
import Axios from "../../Axios";

export default class extends React.Component {
  constructor() {
    super();
    this.state = {
      verified: null,
      loading: true
    };
  }
  componentDidMount() {
    this.redeemKey(this.props.match.params.key);
  }
  get api() {
    return Axios();
  }
  redeemKey = async key => {
    Axios()
      .get(`auth/redeem/${key}`)
      .then(async (res) => {
        if (res.data.success) {
          localStorage.green_member_key = res.data.data;
          ReactGA.event({
            category: 'User',
            action: `Sign In Success (email)`
          });
          this.setState({
            loading: false,
            verified: true,
          });
        } else {
          throw res.data.success;
        }
      })
      .catch(() => {
        this.setState({
          loading: false,
          verified: false,
        });
      });
  };
  render() {
    return this.state.loading ? (
      <div className="grid-center-center" style={{minHeight: "100vh"}}>
        <Loader />
      </div>
    ) : this.state.verified ? (
      <Redirect to="/members" />
    ) : (
      <Redirect to="/sign-in?expired=true" />
    );
  }
}
