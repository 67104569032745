import React from "react";

const Stat = (props) => (
  <div className={`ui-stat ${props.onClick ? "ui-stat-clickable" : ""}`} onClick={() => props.onClick ? props.onClick() : null}>
    <span className={`colour-${props.statColour} ui-title-mega`}>
      {props.stat}
    </span>
    <p className="ui-title-four margin-top-10">{props.title}</p>
    <p className="ui-title-sub margin-top-5">{props.subTitle}</p>
  </div>
);

export default Stat;
