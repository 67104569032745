import React from "react";
import { NavLink } from "react-router-dom";

class Footer extends React.Component {
  render() {
    return (
      <div className="footer-container margin-top-50">
        {/* <TrustBoxContainer /> */}
        <div className="footer">
          {/* <div className="foot-navigation">
            <div>
              <h2 className="ui-title-one colour-white">
                Speak with one of <br />
                our agents.
              </h2>
              <p className="ui-label opacity-50 colour-white margin-top-20">
                Mon - Sun, 7am - 11pm via Telephone. 24/7 Live Chat.
              </p>
              <div className="margin-top-30">
                <button
                  className="ui-btn-primary margin-right-20"
                  onClick={() => window.Intercom("show")}
                >
                  <i className="fa fa-comments margin-right-10" />
                  Start a chat
                </button>
                <a className="ui-btn" href="tel:03452222525">
                  <i
                    className="fa fa-phone margin-right-10"
                    style={{ transform: "rotate(90deg)" }}
                  />
                  0345 222 2525
                </a>
              </div>
            </div>
            <div className="col-2 foot-links">
              <ul>
                <li>
                  <b>Navigation</b>
                </li>
                <li>
                  <NavLink to="/members/home">Home</NavLink>
                </li>
                <li>
                  <NavLink to="/members/readings">Readings</NavLink>
                </li>
                <li>
                  <NavLink to="/members/payments">Bills &amp; Payments</NavLink>
                </li>
                <li>
                  <NavLink to="/members/account">Account</NavLink>
                </li>
                <li>
                  <NavLink to="/members/referrals">Referrals</NavLink>
                </li>
                <li>
                  <NavLink to="/members/support">Support</NavLink>
                </li>
              </ul>
              <ul>
                <li>
                  <b>Support</b>
                </li>
                <li>
                  <a href="https://help.green.energy/en/">FAQs</a>
                </li>
                <li>
                  <a href="mailto:support@green.energy">Email Us</a>
                </li>
                <li>
                  <a href="https://green.energy/static/media/citizens-advice.585a4639.pdf">Citzens Advice</a>
                </li>
                <li>
                  <a href="https://www.citizensadvice.org.uk/consumer/energy/energy-supply/get-help-paying-your-bills/struggling-to-pay-your-energy-bills/">Debt Advice</a>
                </li>
                <li>
                  <a href="https://green.energy/complaints">Complaints</a>
                </li>
                <li>
                  <a href="https://green.energy/terms">Terms</a>
                </li>
              </ul>
            </div>
          </div> */}
          <div className="foot-note">
            <img
              alt="Green"
              src={`https://green.cdn.energy/branding/logo-r-white.svg`}
            />
            <p className="ui-body colour-white" style={{fontSize: 14}}>
              &copy; {new Date().getFullYear()} Green Supplier Limited
            </p>
          </div>
        </div>
      </div>
    );
  }
}

const TrustBox = ({ trustBoxRef }) => (
  <div
    ref={trustBoxRef}
    className="trustpilot-widget padding-50"
    data-locale="en-GB"
    data-template-id="53aa8912dec7e10d38f59f36"
    data-businessunit-id="5cab2a5babf2d70001cc2997"
    data-style-height="140px"
    data-style-width="100%"
    data-theme={document.documentElement.getAttribute("data-theme")}
    data-stars="4,5"
    data-schema-type="Organization"
  >
    {" "}
    <a
      href="https://uk.trustpilot.com/review/green.energy"
      target="_blank"
      rel="noopener noreferrer"
    >
      Trustpilot
    </a>{" "}
  </div>
);
class TrustBoxContainer extends React.Component {
  constructor(props) {
    super(props);
    this.trustBoxRef = React.createRef();
  }
  componentDidMount() {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(this.trustBoxRef.current, true);
    }
  }
  render() {
    return <TrustBox trustBoxRef={this.trustBoxRef} />;
  }
}

export default Footer;
