import React from "react";
import moment from "moment";
import { Helmet } from "react-helmet";
import Loader from "../../components/generics/Loader";
import Table from "../../components/generics/Table";

export default class extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      plan: null,
      details: null
    }
  }
  async componentDidMount() {
    this.getData();
  }
  async getData() {
    const results = await Promise.all([
      await this.props.api.get(`/properties/${this.props.property.id}/rates`),
      await this.props.api.get(`/properties/${this.props.property.id}`),
      await this.props.api.get(`/properties/${this.props.property.id}/bank-details`)
    ])
    this.setState({
      loading: false,
      plan: results[0].data.data,
      details: results[1].data.data,
      bankDetails: results[2].data.data
    });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.property.id !== this.props.property.id) {
      this.setState({ loading: true }, () => this.getData());
    }
  }
  toProperCase(str) {
    return str.replace(
      /\w\S*/g,
      txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
  }
  render() {
    return <div>
      <Helmet>
        <title>Account - Green Members</title>
      </Helmet>
      <div className="banner-compact">
        <h1 className="ui-title-mega colour-white">Your Account</h1>
        <h2 className="ui-title-four ui-title-sub colour-white margin-top-10 opacity-60">
          Your account number is {this.props.property.id}
        </h2>
      </div>
      <div className="padding-50">
        {this.state.loading ? (
          <div className="padding-50 grid-center-center">
            <Loader />
          </div>
        ) : <>
            <div className="container-small">
              <div className="col-1 gap-80 col-start-2">
                <div />
                <div>
                  <h2 className="ui-title-one text-center">Your Personal Information</h2>
                  <h3 className="ui-title-four ui-title-sub text-center margin-top-10">Your contact and property details.</h3>
                </div>
                <div>
                  <Table data={{
                    "Account number": String(this.props.property.id),
                    "Named contact": `${this.state.details.firstName} ${this.state.details.lastName}`,
                    "Contact address": Object.keys(this.state.details.address)
                      .filter(y => this.state.details.address[y])
                      .map(y =>
                        y === "postcode"
                          ? this.state.details.address[y]
                          : this.toProperCase(this.state.details.address[y])
                      )
                      .join(", "),
                    "Payment method": this.state.bankDetails.accountNumber ? `${this.state.bankDetails.accountName} (${this.state.bankDetails.bankName}, ${this.state.bankDetails.accountNumber})` : null,
                    "Email address": this.state.details.email,
                    "Phone number": this.state.details.phone,
                    "MPAN(s)": this.state.details.electric ? this.state.details.electric.meters.length ? this.state.details.electric.meters.map(x => x.mpan).join(", ") : null : null,
                    "MPRN(s)": this.state.details.gas ? this.state.details.gas.meters.length ? this.state.details.gas.meters.map(x => x.mprn).join(", ") : null : null
                  }} />
                  <p className="ui-disclaimer text-center margin-top-30">Please contact support if you wish to update your details.</p>
                </div>
                <div />
                <div>
                  <h2 className="ui-title-one text-center">Your Current Plan</h2>
                  <h3 className="ui-title-four ui-title-sub text-center margin-top-10">The rates you're currently on.</h3>
                </div>
                {this.state.plan.gas || this.state.plan.electric ? <div className="ui-table">
                  <table>
                    <thead>
                      <td></td>
                      {this.state.plan.gas ? <td>Gas</td> : null}
                      {this.state.plan.electric ? <td>Electric</td> : null}
                    </thead>
                    <tbody>
                      <tr>
                        <td className="ui-table-header">Plan Name</td>
                        {this.state.plan.gas ? <td>{this.state.plan.gas.name}</td> : null}
                        {this.state.plan.electric ? <td>{this.state.plan.electric.name}</td> : null}
                      </tr>
                      <tr>
                        <td className="ui-table-header">Plan Type</td>
                        {this.state.plan.gas ? <td>{this.state.plan.gas.type}</td> : null}
                        {this.state.plan.electric ? <td>{this.state.plan.electric.type}</td> : null}
                      </tr>
                      <tr>
                        <td className="ui-table-header">Start Date</td>
                        {this.state.plan.gas ? <td>{moment(this.state.plan.gas.start).format("DD/MM/YYYY")}</td> : null}
                        {this.state.plan.electric ? <td>{moment(this.state.plan.electric.start).format("DD/MM/YYYY")}</td> : null}
                      </tr>
                      <tr>
                        <td className="ui-table-header">End Date</td>
                        {this.state.plan.gas ? <td>{this.state.plan.gas.type !== "Variable" ? moment(this.state.plan.gas.end).format("DD/MM/YYYY") : "N/A"}</td> : null}
                        <td>{this.state.plan.electric ? this.state.plan.electric.type !== "Variable" ? moment(this.state.plan.electric.end).format("DD/MM/YYYY") : "N/A" : null}</td>
                      </tr>
                      {
                        this.state.plan.electric ? !this.state.plan.electric.rates ? this.state.plan.electric.nightRate ? <>
                          <tr>
                            <td className="ui-table-header">Unit Rate</td>
                            {this.state.plan.gas ? <td>{this.state.plan.gas.unitRate.toFixed(3)}p</td> : null}
                            <td>{this.state.plan.electric.unitRate.toFixed(3)}p</td>
                          </tr>
                          <tr>
                            <td className="ui-table-header">Night Rate</td>
                            {this.state.plan.gas ? <td>N/A</td> : null}
                            <td>{this.state.plan.electric.nightRate.toFixed(3)}p</td>
                          </tr>
                        </> : <tr>
                            <td className="ui-table-header">Unit Rate</td>
                            {this.state.plan.gas ? <td>{this.state.plan.gas.unitRate.toFixed(3)}p</td> : null}
                            <td>{this.state.plan.electric.unitRate.toFixed(3)}p</td>
                          </tr> : <>
                            {
                              this.state.plan.electric.rates.map(x => <tr>
                                <td className="ui-table-header">Unit Rate ({x.name})</td>

                                <td>{x.rate.toFixed(3)}p</td>
                              </tr>)
                            }
                          </> : null
                      }
                      <tr>
                        <td className="ui-table-header">Standing Charge</td>
                        {this.state.plan.gas ? <td>{this.state.plan.gas.standingCharge.toFixed(2)}p</td> : null}
                        {this.state.plan.electric ? <td>{this.state.plan.electric.standingCharge.toFixed(2)}p</td> : null}
                      </tr>
                      <tr>
                        <td className="ui-table-header">Exit Fee</td>
                        {this.state.plan.gas ? <td>£{this.state.plan.gas.exitFees ? this.state.plan.gas.exitFees : 0}</td> : null}
                        {this.state.plan.electric ? <td>£{this.state.plan.electric.exitFees ? this.state.plan.electric.exitFees : 0}</td> : null}
                      </tr>
                      {
                        this.state.plan.electric ? this.state.plan.electric.rates ? <tr>
                          <td className="ui-table-header">Notice Period (Days)</td>
                          {this.state.plan.electric ? <td>{this.state.plan.electric.noticePeriod ? this.state.plan.electric.noticePeriod : 0}</td> : null}
                        </tr> : null : null
                      }
                    </tbody>
                  </table>
                </div> : <p className="ui-label text-center">No plan found on your account.</p>}
                <div />
              </div>
            </div>
          </>}
      </div>
    </div>
  }
}