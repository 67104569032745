import React from "react";
import moment from "moment";
import { Helmet } from "react-helmet";
import Stat from "../../components/generics/Stat";
import Loader from "../../components/generics/Loader";
import SubmitReadingModal from "../../components/modals/SubmitReading";
import Table from "../../components/generics/Table";

export default class extends React.Component {
  constructor() {
    super();
    this.state = {
      submitModal: false,
      smartRegistered: false,
      readingsDue: null,
      readings: null,
      lastGas: null,
      lastElec: null,
      loading: true
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.property.id !== this.props.property.id) {
      this.setState({ loading: true }, () => this.getData());
    }
  }
  async componentDidMount() {
    this.getData();
  }
  async getData() {
    try {
      const smartRegistered = await this.props.api.get(`/smart/interest`);
      this.setState({
        smartRegistered: smartRegistered.data.data
      });
    } catch (e) {

    }
    const results = await Promise.all([
      await this.props.api.get(
        `/properties/${this.props.property.id}/readings-due`
      ),
      await this.props.api.get(`/properties/${this.props.property.id}/readings`)
    ]);
    console.log(results[1].data.data.gas[0]);
    this.setState({
      readingsDue: moment(results[0].data.data),
      readings: results[1].data.data,
      lastGas: results[1].data.data.gas.length ? results[1].data.data.gas[0].meters.length ? results[1].data.data.gas[0].meters[0].readings.filter(x => x.typeDescription === "Customer" || x.typeDescription === "Actual").length ? results[1].data.data.gas[0].meters[0].readings.filter(x => x.typeDescription === "Customer" || x.typeDescription === "Actual").sort((a, b) => moment(a.date).isAfter(moment(b.date)))[0] : null : null : null,
      lastElec: results[1].data.data.electric.length ? results[1].data.data.electric[0].meters.length ? results[1].data.data.electric[0].meters[0].registers[0].readings.filter(x => x.typeDescription === "Customer" || x.typeDescription === "Actual").length ? results[1].data.data.electric[0].meters[0].registers[0].readings.filter(x => x.typeDescription === "Customer" || x.typeDescription === "Actual").sort((a, b) => moment(a.date).isAfter(moment(b.date)))[0] : null : null : null,
      loading: false
    });
  }
  registerSmart = async () => {
    if (!this.state.smartRegistered) {
      this.setState({ smartRegistered: true });
      await this.props.api.post(`/smart/interest`);
    }
  }
  render() {
    return (
      <div>
        <Helmet>
          <title>Readings - Green Members</title>
        </Helmet>
        {this.state.submitModal ? <SubmitReadingModal api={this.props.api} property={this.props.property} onExit={() => this.setState({
          submitModal: false
        })} /> : null}
        <div className="banner-compact">
          <h1 className="ui-title-mega colour-white">Meter Readings</h1>
          <h2 className="ui-title-four ui-title-sub colour-white margin-top-10 opacity-60">
            Submit and view your meter readings.
          </h2>
          <div className="banner-buttons">
            <button className="ui-btn-secondary margin-top-40" onClick={() => this.setState({ submitModal: true })}>Submit meter reading</button>
            <button className="ui-btn margin-top-40 margin-left-20" onClick={this.registerSmart}><i className={`fa fa-${this.state.smartRegistered ? "check" : "tachometer-slow"} margin-right-10`} />{this.state.smartRegistered ? "Smart registered" : "Register for smart"}</button>
          </div>
        </div>
        <div className="padding-50">
          {this.state.loading ? (
            <div className="padding-50 grid-center-center">
              <Loader />
            </div>
          ) : <>
              <div className={`col-${this.state.lastElec && this.state.lastGas ? "3" : this.state.lastGas || this.state.lastElec ? "2" : "1"}`}>
                <Stat
                  stat={this.state.readingsDue.isValid() ? this.state.readingsDue.format("Do MMM") : "N/A"}
                  statColour="blue"
                  title="Readings Date"
                  subTitle="Submit on this date"
                />
                {
                  this.state.lastGas ? <Stat
                    stat={this.state.lastGas.value}
                    statColour={"blue"}
                    title="Last Gas Reading"
                    subTitle={moment(this.state.lastGas.date).format("Do MMMM YYYY")}
                  /> : null
                }

                {
                  this.state.lastElec ? <Stat
                    stat={this.state.lastElec.value}
                    statColour={"blue"}
                    title="Last Electric Reading"
                    subTitle={moment(this.state.lastElec.date).format("Do MMMM YYYY")}
                  /> : null
                }
              </div>
              <div className="container-small">
                <div className="col-1 gap-80 col-start-2">
                  <div />
                  <div>
                    <h2 className="ui-title-one text-center">Your Previous Readings</h2>
                    <h3 className="ui-title-four ui-title-sub text-center margin-top-10">See historic readings.</h3>
                  </div>
                  {
                    this.state.readings.electric.length ? this.state.readings.electric[0].meters.map(m => <div>
                      <p className="ui-title-five text-center margin-bottom-30">Electric ({m.serial})</p>
                      {
                        !m.registers.reduce((a, x) => {
                          x.readings.forEach(r => a.push({
                            bandDescription: x.bandDescription,
                            value: r.value,
                            date: moment(r.date),
                            typeDescription: r.typeDescription
                          }));
                          return a;
                        }, []).length ? <p className="ui-label text-center">No electric readings found on your account.</p> : <Table data={m.registers.reduce((a, x) => {
                          x.readings.forEach(r => a.push({
                            bandDescription: x.bandDescription,
                            value: r.value,
                            date: moment(r.date),
                            typeDescription: r.typeDescription
                          }));
                          return a;
                        }, []).sort((a, b) => b.date - a.date).map(r => ({
                          "Type": <>
                            <i style={{ marginRight: 10, color: r.typeDescription === "Estimate" ? "#4C494B" : r.typeDescription === "Actual" ? "#30AF5F" : r.typeDescription === "Customer" ? "#37A9E0" : "#30AF5F" }} className={`fa fa-${r.typeDescription === "Estimate" ? "chevron-right" : r.typeDescription === "Actual" ? "shield-check" : r.typeDescription === "Customer" ? "user-check" : "shield-check"}`} />
                            {r.typeDescription === "Customer" ? "By you" : r.typeDescription}
                          </>,
                          "Date": r.date.format("DD/MM/YYYY"),
                          "Register": r.bandDescription,
                          "Reading": String(r.value)
                        }))} />
                      }
                    </div>) : null
                  }
                  {
                    this.state.readings.gas.length ? this.state.readings.gas[0].meters.map(m => <div>
                      <p className="ui-title-five text-center margin-bottom-30">Gas ({m.serial})</p>
                      {
                        !m.readings.length ? <p className="ui-label text-center">No gas readings found on your account.</p> : <Table data={m.readings.map(r => ({
                          "Type": <>
                            <i style={{ marginRight: 10, color: r.typeDescription === "Estimate" ? "#4C494B" : r.typeDescription === "Actual" ? "#30AF5F" : r.typeDescription === "Customer" ? "#37A9E0" : "#30AF5F" }} className={`fa fa-${r.typeDescription === "Estimate" ? "chevron-right" : r.typeDescription === "Actual" ? "shield-check" : r.typeDescription === "Customer" ? "user-check" : "shield-check"}`} />
                            {r.typeDescription === "Customer" ? "By you" : r.typeDescription}
                          </>,
                          "Date": moment(r.date).format("DD/MM/YYYY"),
                          "Reading": String(r.value)
                        }))} />
                      }
                    </div>) : null
                  }
                  <div />
                </div>
              </div></>}
        </div>
      </div>
    );
  }
}