import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import ReactGA from "react-ga";

import Routes from "./js/routes/Main";

import "./scss/App.scss";

class App extends React.Component {
  componentDidMount() {
    this.initServices();
    window.addEventListener('resize', this.monitorVh);
  }
  monitorVh(){
    const doc = document.documentElement
    doc.style.setProperty('--app-height', `${window.innerHeight}px`)
  }
  initServices() {
    window.Intercom("boot", {
      app_id: "l3yxef0h"
    });
    ReactGA.initialize('UA-131205113-3');
    ReactGA.pageview(window.location.pathname + window.location.search);
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      document.documentElement.setAttribute("data-theme", "dark");
    }
    window.matchMedia('(prefers-color-scheme: dark)').addListener(e => {
      if(e.matches){
        document.documentElement.setAttribute("data-theme", "dark");
      } else {
        document.documentElement.setAttribute("data-theme", "light");
      }
    });
  }
  render() {
    return (
      <Elements stripe={loadStripe("pk_live_MgGeu7oaA4UyYWgJcbKK5RDA00uMTxtccS")}>
        <BrowserRouter>
          <Route path="/" component={Routes} />
        </BrowserRouter>
      </Elements>
    );
  }
}

export default App;
