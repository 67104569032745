import React from "react";
import { Document, Page, pdfjs } from "react-pdf";
import Modal from "../generics/Modal";
import Card from "../generics/Card";
import Loader from "../generics/Loader";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class BillModal extends React.Component {
    constructor() {
        super();
        this.state = {
            loading: true,
            numPages: null,
            currentPage: 1,
            data: null
        }
    }
    async componentDidMount() {
        const res = await this.props.api.get(`/properties/${this.props.property.id}/invoices/${this.props.billId}`);
        this.setState({
            data: res.data.data,
            loading: false
        });
    }
    handleExit = e => {
        if (e.target.className.indexOf("exitable") > -1) {
            this.props.onExit(false);
        }
    }
    render() {
        return (
            <Modal><div
                className="exitable animated duration-2 fadeIn"
                onClick={this.handleExit}
            >
                <Card>
                    {this.state.loading ? <div className="padding-30"><Loader /></div> : <div className="bill-viewer">
                        <Document loading={<div className="padding-30"><Loader /></div>} onLoadSuccess={({ numPages }) => this.setState({ numPages })} file={`data:application/pdf;base64,${this.state.data}`}>
                            <Page loading={<div className="padding-30"><Loader /></div>} pageNumber={this.state.currentPage} width={600} />
                        </Document>
                        <div className="bill-controls">
                            <div>
                                <button className="ui-btn-secondary ui-btn-flat ui-btn-compact" onClick={() => {
                                    const byteCharacters = atob(this.state.data);
                                    const byteNumbers = new Array(byteCharacters.length);
                                    for (let i = 0; i < byteCharacters.length; i++) {
                                      byteNumbers[i] = byteCharacters.charCodeAt(i);
                                    }
                                    const byteArray = new Uint8Array(byteNumbers);
                                    const blob = new Blob([byteArray], { type: "application/pdf" });
                                    const el = document.createElement("a");
                                    el.download = "your_green_bill.pdf";
                                    el.href = window.URL.createObjectURL(blob);
                                    el.addEventListener("onclick", () => {
                                      if (navigator.msSaveOrOpenBlob) {
                                        navigator.msSaveOrOpenBlob(blob, `your_green_bill.pdf`);
                                        return false;
                                      }
                                    });
                                    document.body.appendChild(el);
                                    el.click();
                                    document.body.removeChild(el);
                                }}>
                                    <i className="fa fa-file margin-right-10"></i>
                                Download PDF
                                </button>
                            </div>
                            <div className="page-controls">
                                <i className="fa fa-chevron-left" onClick={() => this.state.currentPage > 1 ? this.setState({ currentPage: this.state.currentPage - 1 }) : null}></i>
                            Page {this.state.currentPage} of {this.state.numPages}
                                <i className="fa fa-chevron-right" onClick={() => this.state.currentPage < this.state.numPages ? this.setState({ currentPage: this.state.currentPage + 1 }) : null}></i>
                            </div>
                        </div>
                    </div>}

                </Card>
            </div>
            </Modal>
        );
    }
}

export default BillModal;
