import React from "react";

class Table extends React.Component {
  formatCell(d){
    if (typeof d === "undefined" || d === null) {
      return "N/A";
    } else if (typeof d === "boolean") {
      return d ? "Yes" : "No";
    } else if (typeof d === "number") {
      return d.toLocaleString();
    } else if (d instanceof Date) {
      return d.toLocaleString();
    } else if (typeof d === "object") {
      return d;
    } else {
      return String(d);
    }
  }
  render() {
    return (
      <div className={`ui-table ${this.props.className}`}>
        <table>
          <thead>
            <tr>
              {Array.isArray(this.props.data) ? this.props.data.length ? Object.keys(this.props.data[0]).map(x => <td>{x}</td>) : null : null}
            </tr>
          </thead>
          <tbody>
            {Array.isArray(this.props.data) ? this.props.data.map((d,i) => (
              <tr onClick={() => this.props.onClick ? this.props.onClick(i) : null}>
                {Object.values(d).map((x,i) => <td>{this.formatCell(x)}</td>)}
              </tr>
            )) : Object.keys(this.props.data).map((k,i) => <tr onClick={() => this.props.onClick ? this.props.onClick(i) : null}>
                <td className="ui-table-header">{k}</td>
                <td>{this.formatCell(this.props.data[k])}</td>
            </tr>)}
          </tbody>
        </table>
      </div>
    );
  }
}

export default Table;
