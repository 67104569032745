import React from "react";

const RowStat = (props) => (
  <div className="ui-row-stat col-2 col-nr" style={props.style ? props.style : {}}>
    <p className={`${props.basic ? "opacity-60" : ""} ui-label`}>{props.title}</p>
    <span className={`colour-${props.statColour} ${props.basic ? "ui-label" : "ui-title-four"}`}>
      {props.stat}
    </span>
  </div>
);

export default RowStat;
