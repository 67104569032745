import moment from "moment";
import React from "react";
import { NavLink } from "react-router-dom";
import GenericMessage from "../../components/modals/GenericMessage";

class Header extends React.Component {
  constructor() {
    super();
    this.state = {
      showMenu: false,
      signOutModal: false
    }
  }
  render() {
    return (
      <>
      {/* {
        moment().isSame(moment().set("date", 1).set("month", 1)) && <div className="bg-green colour-white padding-14 ui-body text-center" style={{fontSize: 14}}>
        <p>Limited time offer: <b>Top up your account and get 10% extra. Ends midnight on 01/02/2021. <a href="https://green.energy/green-monday-terms" target="_blank" className="colour-white">T&amp;Cs Apply.</a></b></p>
      </div>
      } */}
        <div className="header">
          {this.state.signOutModal ? <GenericMessage title="Are you sure you want to sign out?" subTitle="You will need to get a new magic link/code." confirmButton={true} confirmText="Sign out" body="You can stay logged in on a private computer without the need to sign back in by just closing the browser." onConfirm={() => {
            localStorage.removeItem("green_member_key");
            localStorage.removeItem("green_property_id");
            window.Intercom("shutdown");
            window.location.reload();
          }} onExit={() => this.setState({ signOutModal: false })} /> : null}
          <NavLink
            to={`/members/home`}
          ><img
              alt="Green"
              src={`https://green.cdn.energy/branding/logo-white.svg`}
            /></NavLink>
          <div className="header-mobile-trigger" onClick={() => this.setState({ showMenu: !this.state.showMenu })}>
            <i className={`fal ${this.state.showMenu ? 'fa-times' : 'fa-bars'}`}></i>
          </div>
          <div className={`header-navigation ${this.state.showMenu ? 'mobile-show' : 'mobile-hide'}`}>
            {/* <NavLink
              className="ui-label colour-white"
              to={`/members/home`}
              activeClassName="active"
              onClick={() => this.setState({ showMenu: false })}
            >
              Home
          </NavLink>
            <NavLink
              className="ui-label colour-white"
              to={`/members/readings`}
              activeClassName="active"
              onClick={() => this.setState({ showMenu: false })}
            >
              Readings
          </NavLink>
            <NavLink
              className="ui-label colour-white"
              to={`/members/payments`}
              activeClassName="active"
              onClick={() => this.setState({ showMenu: false })}
            >
              Bills &amp; Payments
          </NavLink>
          <NavLink
              className="ui-label colour-white"
              to={`/members/account`}
              activeClassName="active"
              onClick={() => this.setState({ showMenu: false })}
            >
              Account
          </NavLink>
          <NavLink
              className="ui-label colour-white"
              to={`/members/referrals`}
              activeClassName="active"
              onClick={() => this.setState({ showMenu: false })}
            >
              Referrals
          </NavLink>
            <NavLink
              className="ui-label colour-white"
              to={`/members/support`}
              activeClassName="active"
              onClick={() => this.setState({ showMenu: false })}
            >
              Support
          </NavLink> */}
            <a
              href="#"
              className="ui-label colour-white"
              onClick={e => {
                e.preventDefault();
                this.setState({
                  signOutModal: true,
                  showMenu: false
                });
              }}
            >
              <i className="fa fa-sign-out-alt" />
            </a>
          </div>
        </div>
      </>
    );
  }
}

export default Header;
