import React from "react";
import { Redirect } from "react-router-dom";
import Routes from "../../routes/Members";
import Layout from "../../layouts/members";
import Loader from "../../components/generics/Loader";
import Axios from "../../../Axios";
import decodeJWT from "jwt-decode";

export default class extends React.Component {
  constructor() {
    super();
    this.state = {
      verified: null,
      loading: true,
      header: null,
      properties: [],
      currentProperty: null,
      serviceAccount: false,
      serviceAccountPropertySelected: false
    };
  }
  componentDidMount() {
    this.verifyToken();
  }
  get api() {
    return Axios(localStorage.green_member_key);
  }
  setProperty = async sa => {
    if (sa) {
      if (this.state.properties.length === 0) {
        const properties = await this.api.get(`properties/${localStorage.green_property_id}`);
        this.setState({
          properties: [properties.data.data],
          currentProperty: properties.data.data
        }, () => this.updateIntercom());
      }
    } else {
      if (this.state.properties.length === 0) {
        const properties = await this.api.get("properties");
        if (!localStorage.green_property_id || properties.data.data.findIndex(x => x.id == localStorage.green_property_id) === -1) localStorage.green_property_id = properties.data.data[0].id;
        this.setState({
          properties: properties.data.data.sort((a, b) => a.id - b.id),
          currentProperty: properties.data.data.find(x => x.id == localStorage.green_property_id)
        }, () => this.updateIntercom());
      } else {
        this.setState({
          currentProperty: this.state.properties.find(x => x.id == localStorage.green_property_id)
        }, () => this.updateIntercom());
      }
    }
  }
  updateIntercom = async () => {
    window.Intercom("boot", {
      app_id: "l3yxef0h",
      user_id: localStorage.green_property_id,
      name: this.state.currentProperty.firstName
    });
    window.Intercom("update");
  }
  verifyToken = async () => {
    Axios(localStorage.green_member_key)
      .get("auth/check")
      .then(async (res) => {
        if (res.data.success) {
          if (decodeJWT(localStorage.green_member_key).sub) {
            if (!!localStorage.green_property_id) await this.setProperty(true);
            this.setState({
              loading: false,
              verified: true,
              serviceAccount: true,
              serviceAccountPropertySelected: !!localStorage.green_property_id
            });
          } else {
            await this.setProperty();
            this.setState({
              loading: false,
              verified: true,
            });
          }
        } else {
          throw res.data.success;
        }
      })
      .catch(() => {
        this.setState({
          loading: false,
          verified: false,
        });
      });
  };
  setHeader = (header) => {
    this.setState({ header });
  };
  render() {
    return this.state.loading ? (
      <div className="grid-center-center" style={{ minHeight: "100vh" }}>
        <Loader />
      </div>
    ) : this.state.verified ? this.state.serviceAccount && !this.state.serviceAccountPropertySelected ? (
      <Redirect to="/account-explorer" />
    ) : (
        <Layout header={this.state.header} properties={this.state.properties} serviceAccount={this.state.serviceAccount} setProperty={this.setProperty}>
          <Routes api={this.api} setHeader={this.setHeader} property={this.state.currentProperty} />
        </Layout>
      ) : (
          <Redirect to="/sign-in" />
        );
  }
}
