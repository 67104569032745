import React from "react";
import Loader from "./Loader";

const Card = props => (
  <div
    className={`ui-card col-1 ${props.className ? props.className : ""}`}
    style={props.style}
    onClick={() => props.onClick ? props.onClick() : null}
  >
    {props.loading ? (
      <div className="grid-center-center">
        <Loader />
      </div>
    ) : props.message ? (
      <div className="ui-card-message grid-center-center">
        {props.messageIcon ? <i className={`fad fa-${props.messageIcon}`}/> : null}
        <p>{props.message}</p>
      </div>
    ) : (
      props.children
    )}
  </div>
);

export default Card;
