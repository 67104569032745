import React from "react";
import Axios from "../../../Axios";
import decodeJWT from "jwt-decode";

class PropertySelector extends React.Component {
  constructor(){
    super();
    this.state = {
      accountNumber: "",
      invalidAccountNumber: false
    }
  }
  viewAccount = async e => {
    e.preventDefault();
    try {
      const res = await Axios(localStorage.green_member_key).get(`properties/${this.state.accountNumber}`);
      if(res.data.success){
        localStorage.green_property_id = this.state.accountNumber;
        window.location.reload();
      } else {
        this.setState({invalidAccountNumber: true});
      }
    } catch (e) {
      this.setState({invalidAccountNumber: true});
    }
  }
  render() {
    return (
      <div className="sa-navigator">
        <div>
          <p className="ui-body" style={{opacity: 1}}>Authorized as <b style={{textTransform: "capitalize"}}>{decodeJWT(localStorage.green_member_key).sub}</b> on account <b className="colour-blue">{this.props.properties[0].id}</b></p>
          <div>
          <form className="ui-input-group" onSubmit={this.viewAccount}>
              <input
                className="ui-input"
                type="number"
                required
                placeholder="Account number"
                style={this.state.invalidAccountNumber ? {borderColor: "orange"} : {}}
                onChange={(e) => {
                  this.setState({
                    accountNumber: e.currentTarget.value
                  })
                }}
                value={this.state.accountNumber}
              />
              <button className="ui-btn ui-btn-primary">View</button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default PropertySelector;
