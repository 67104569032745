import React from "react";
import moment from "moment";
import { Helmet } from "react-helmet";
import Stat from "../../components/generics/Stat";
import Loader from "../../components/generics/Loader";
import TopUpModal from "../../components/modals/TopUp";
import BillModal from "../../components/modals/Bill";
import SubmitReadingModal from "../../components/modals/SubmitReading";
import {
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Cell,
  Pie,
  PieChart,
  Legend,
  LineChart,
} from "recharts";

const colors = [
  "#30AF5F",
  "#4E59A3",
  "#a1a1a1",
  "#E67F25",
  "#16a085",
  "#27ae60",
  "#2980b9",
  "#8e44ad",
  "#f39c12",
  "#d35400",
  "#c0392b",
];

export default class extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      balance: null,
      readingsDue: null,
      paymentPlan: null,
      latestBill: null,
      topUpModal: false,
      billModal: false,
      submitModal: false,
      billId: null
    };
  }
  componentDidMount() {
    this.getData();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.property.id !== this.props.property.id) {
      this.setState({ loading: true }, () => this.getData());
    }
  }
  async getData() {
    const results = await Promise.all([
      await this.props.api.get(`/properties/${this.props.property.id}/balance`),
      await this.props.api.get(
        `/properties/${this.props.property.id}/invoices`
      ),
      await this.props.api.get(`/properties/${this.props.property.id}/plan`),
      await this.props.api.get(
        `/properties/${this.props.property.id}/readings-due`
      ),
    ]);
    const bills = results[1].data.data.sort(
      (a, b) => moment(b.date) - moment(a.date)
    );
    this.setState(
      {
        loading: false,
        balance: results[0].data.data,
        readingsDue: moment(results[3].data.data),
        paymentPlan: results[2].data.data,
        latestBill: bills.length ? bills[0] : null,
        bills: bills
          .reduce((a, x) => {
            const date = moment(x.date);
            const monthIndex = a.findIndex((y) =>
              moment(y.date).isSame(date, "month")
            );
            if (monthIndex > -1) {
              a[monthIndex].total += x.cost.total;
            } else {
              a.push({
                date,
                total: x.cost.total,
              });
            }
            return a;
          }, [])
          .sort((a, b) => b.date - a.date)
          .reverse().map(x => ({ ...x, date: x.date.format("MMM YYYY") })),
      }, () => console.log(this.state.paymentPlan));
  }
  render() {
    return (
      <div>
        {this.state.topUpModal ? <TopUpModal api={this.props.api} property={this.props.property} onExit={() => this.setState({ topUpModal: false })} /> : null}
        {this.state.billModal ? <BillModal billId={this.state.billId} api={this.props.api} property={this.props.property} onExit={() => this.setState({ billModal: false })} /> : null}
        {this.state.submitModal ? <SubmitReadingModal api={this.props.api} property={this.props.property} onExit={() => this.setState({
          submitModal: false
        })} /> : null}
        <Helmet>
          <title>Home - Green Members</title>
        </Helmet>
        <div className="banner">
          <h1 className="ui-title-mega colour-white">
            Good {
              new Date().getHours() < 12
                ? "Morning"
                : new Date().getHours() >= 12 && new Date().getHours() < 17
                  ? "Afternoon"
                  : "Evening"
            }, {this.props.property.firstName}
          </h1>
          <h2 className="ui-title-three ui-title-sub colour-white margin-top-10 opacity-60">
            Here’s an overview of your account.
          </h2>
        </div>
        <div className="padding-50">
          {this.state.loading ? (
            <div className="padding-50 grid-center-center">
              <Loader />
            </div>
          ) : (
              <div className="col-1 gap-80">
                <div className="col-4">
                  <Stat
                    stat={`£${this.state.balance}`}
                    statColour={this.state.balance >= 0 ? "green" : "orange"}
                    title="Your Balance"
                    subTitle={`You’re in ${
                      this.state.balance >= 0 ? "credit" : "debit"
                      }`}
                    onClick={() => this.setState({
                      topUpModal: true
                    })}
                  />
                  <Stat
                    stat={
                      this.state.latestBill
                        ? `£${this.state.latestBill.cost.total.toFixed(2)}`
                        : "N/A"
                    }
                    statColour="green"
                    title="Latest Bill"
                    subTitle={
                      this.state.latestBill
                        ? moment(this.state.latestBill.date).format(
                          "Do MMMM YYYY"
                        )
                        : "No bills yet"
                    }
                    onClick={() => this.state.latestBill ? this.setState({ billModal: true, billId: this.state.latestBill.id }) : null}
                  />
                  <Stat
                    stat={this.state.readingsDue.isValid() ? this.state.readingsDue.format("Do MMM") : "N/A"}
                    statColour="blue"
                    title="Readings Date"
                    subTitle="Submit on this date"
                    onClick={() => this.setState({
                      submitModal: true
                    })}
                  />
                  <Stat
                    stat={
                      this.state.paymentPlan
                        ? `£${this.state.paymentPlan.amount.toFixed(2)}`
                        : "N/A"
                    }
                    statColour="blue"
                    title="Direct Debit"
                    subTitle={
                      this.state.paymentPlan
                        ? `${this.state.paymentPlan.collectionDate}${this.state.paymentPlan.collectionDate === 1 ? "st" : this.state.paymentPlan.collectionDate === 2 ? "nd" : this.state.paymentPlan.collectionDate === 3 ? "rd" : "th"} of each month`
                        : "No plan set up"
                    }
                  />
                </div>
                <div>
                  <h2 className="ui-title-one text-center">Your Recent Bills</h2>
                  <h3 className="ui-title-three ui-title-sub text-center margin-top-10">Highlighting your spend since joining us.</h3>
                </div>
                <div className="col-1">
                  {!this.state.bills.length ? <p className="ui-label text-center">No bills found on your account.</p> : <div style={{ height: 450, maxWidth: "800px", width: "99%", justifySelf: "center" }}>
                    <ResponsiveContainer>
                      <BarChart
                        data={this.state.bills}
                      >
                        <CartesianGrid strokeDasharray="5 5" strokeOpacity={0} />
                        <XAxis height={20} dataKey="date" />
                        <YAxis
                          width={60}
                          type="number"
                          domain={[0, 'dataMax']}
                          tickFormatter={v => `£${v.toFixed(2)}`}
                        />
                        <Tooltip
                          cursor={{
                            fill: getComputedStyle(
                              document.documentElement
                            ).getPropertyValue("--ui-border"),
                          }}
                          formatter={(value, i, x) => `£${value.toFixed(2)}`}
                        />
                        <Bar
                          isAnimationActive={false}
                          dataKey={"total"}
                          fill={colors[1]}
                          maxBarSize={30}
                        >
                          {
                            this.state.bills.map((entry, index) => {
                              const percentile = [...this.state.bills].sort((a, b) => a.total - b.total).findIndex(x => x.date === entry.date) / (this.state.bills.length - 1) * 100;
                              return (
                                <Cell
                                  key={`cell-${index}`}
                                  fill={
                                    percentile >= 75 ? colors[3] : percentile <= 25 ? colors[0] : colors[2]
                                  }
                                />
                              )
                            })
                          }
                        </Bar>
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                  }
                </div>
              </div>
            )}
        </div>
      </div>
    );
  }
}
