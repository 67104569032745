import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import SignIn from "../views/SignIn";
import Members from "../views/members";
import Auth from "../views/Auth";
import ServiceAccountExplorer from "../views/ServiceAccountExplorer";

const Routes = props => (
  <Switch>
      <Route path="/sign-in" component={SignIn} />
      <Route path="/account-explorer" component={ServiceAccountExplorer} />
      <Route path="/members" component={Members} />
      <Route path="/auth/redeem/:key" component={Auth} />
      <Route render={() => <Redirect to="/members" />} />
  </Switch>
);

export default Routes;
