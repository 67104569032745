import React from "react";
import { NavLink } from "react-router-dom";

class PropertySelector extends React.Component {
  toProperCase(str) {
    return str.replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
  }
  render() {
    return (
      <div className="property-selector">
        <div>
          <div style={{display:"flex"}}>
            <a className="ui-label margin-right-10" href="tel:03452222525">
              <i
                className="fa fa-phone margin-right-10"
                style={{ transform: "rotate(90deg)" }}
              />
              0345 222 2525
            </a>
            <a className="ui-label" href="mailto:support@green.energy">
              <i
                className="fa fa-envelope margin-right-10"
              />
              support@green.energy
            </a>
          </div>
          <select className="ui-select" onChange={e => {
            localStorage.green_property_id = e.currentTarget.value;
            this.props.setProperty();
          }}>
            {this.props.properties.map((x, i) => (
              <option
                value={x.id}
                selected={x.id == localStorage.green_property_id}
              >
                {x.id} - {x.address},{" "}
                {x.postcode}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  }
}

export default PropertySelector;
