import React from "react";
import {Helmet} from "react-helmet";
import Card from "../components/generics/Card";
import Axios from "../../Axios";
import Video from "../../assets/video.mp4";
import ReactGA from "react-ga";

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      loading: false,
      invalid: false,
      mobilePin: "",
      mobilePinInvalid: false,
      isMobileNo: false,
      sentMagicLink: false,
      expired: new URLSearchParams(props.location.search).get("expired"),
    };
  }
  signInWithCode = async (e) => {
    e.preventDefault();
    const res = await Axios().get("auth/redeem/" + this.state.mobilePin);
    if (res.data.success) {
      localStorage.green_member_key = res.data.data;
      ReactGA.event({
        category: 'User',
        action: `Sign In Success (phone)`
      });
      this.props.history.push("/members");
    } else {
      this.setState({
        mobilePinInvalid: true
      });
    }
  }
  signInAttempt = async (e) => {
    e.preventDefault();
    this.setState(
      {
        loading: true,
      },
      async () => {
        const isMobileNo = !(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.state.user));
        try {
          const res = await Axios().post("auth/token", {
            user: this.state.user,
            type: isMobileNo ? "phone" : "email",
            callback: "https://members.green.energy/auth/redeem/",
          });
          ReactGA.event({
            category: 'User',
            action: `Sign In Attempt (${isMobileNo ? "phone" : "email"})`
          });
          if (res.data.success) {
            this.setState({
              loading: false,
              sentMagicLink: true,
              isMobileNo
            });
          } else {
            this.setState({
              loading: false,
              invalid: true
            });
          }
        } catch (e) {
          this.setState({
            loading: false,
            invalid: true,
          });
        }
      }
    );
  };
  render() {
    return (
      <div className="col-1 sign-in">
        <Helmet>
            <title>Sign In - Green Members</title>
        </Helmet>
        <Card
          loading={this.state.loading}
          className="padding-50 padding-top-60 padding-bottom-60 gap-50 margin-40"
        >
          <img
            alt="Green"
            src={`https://green.cdn.energy/branding/logo-r${
              document.documentElement.getAttribute("data-theme") === "light"
                ? ""
                : "-white"
            }.svg`}
          />
          {this.state.sentMagicLink ? this.state.isMobileNo ? (
            <>
            <div>
              <h1 className="ui-title-one">
                Great, we've sent you a magic code.
              </h1>
              <h2 className="ui-title-four ui-title-sub margin-top-10">
                Please enter the code below to get signed in.
              </h2>
            </div>
            <div>
                <form className="ui-input-group" onSubmit={this.signInWithCode}>
                  <input
                    className="ui-input"
                    type="number"
                    required
                    placeholder="6 digit code"
                    onChange={(e) => {
                      this.setState({
                        mobilePin: e.currentTarget.value.substring(0,6)
                      })
                    }}
                    value={this.state.mobilePin}
                  />
                  <button className="ui-btn ui-btn-primary">Confirm</button>
                </form>
                {this.state.mobilePinInvalid ? (
                  <p className="ui-label colour-orange margin-top-5">
                    That code may have expired or been entered incorrect 😕
                  </p>
                ) : null}
              </div>
            <p className="ui-label">
              We sent the SMS to <b>{this.state.user}</b>, if this is wrong
              you can{" "}
              <span
                className="ui-link"
                onClick={() => this.setState({ sentMagicLink: false })}
              >
                go back
              </span>
              .
            </p>
            <p className="ui-label" style={{ fontSize: 18 }}>
              Can't sign in?{" "}
              <span
                className="ui-link"
                onClick={() =>
                  window.open(
                    "https://help.green.energy/?q=signing+in",
                    "_blank"
                  )
                }
              >
                Start troubleshooting
              </span>
              .
            </p>
          </>
          ) : (
            <>
              <div>
                <h1 className="ui-title-one">
                  Great, we've sent you an email.
                </h1>
                <h2 className="ui-title-four ui-title-sub margin-top-10">
                  Click the link on that email and it'll sign you in.
                </h2>
              </div>
              <p className="ui-label">
                We've emailed <b>{this.state.user}</b>, if this is wrong
                you can{" "}
                <span
                  className="ui-link"
                  onClick={() => this.setState({ sentMagicLink: false })}
                >
                  go back
                </span>
                .
              </p>
              <p className="ui-label" style={{ fontSize: 18 }}>
                Can't sign in?{" "}
                <span
                  className="ui-link"
                  onClick={() =>
                    window.open(
                      "https://help.green.energy/?q=signing+in",
                      "_blank"
                    )
                  }
                >
                  Start troubleshooting
                </span>
                .
              </p>
            </>
          ) : (
            <>
              <div>
                {this.state.expired ? (
                  <h1 className="ui-title-one">
                    Your email link has expired.
                  </h1>
                ) : (
                  <h1 className="ui-title-one">
                    Good{" "}
                    {new Date().getHours() < 12
                      ? "morning"
                      : new Date().getHours() >= 12 &&
                        new Date().getHours() < 17
                      ? "afternoon"
                      : "evening"}
                    , welcome to Green.
                  </h1>
                )}
                <h2 className="ui-title-four ui-title-sub margin-top-10">
                  Please enter your account email below.
                </h2>
              </div>
              <div>
                <form className="ui-input-group" onSubmit={this.signInAttempt}>
                  <input
                    className="ui-input"
                    type="email"
                    required
                    placeholder="Email address"
                    onChange={(e) =>
                      this.setState({ user: e.currentTarget.value.toLowerCase() })
                    }
                    value={this.state.user}
                  />
                  <button className="ui-btn ui-btn-flat ui-btn-primary">Sign In</button>
                </form>
                {this.state.invalid ? (
                  <p className="ui-label colour-orange margin-top-5">
                    We couldn't find an account with that email/number 😕
                  </p>
                ) : null}
              </div>
              <p className="ui-label" style={{ fontSize: 18 }}>
                Having issues?{" "}
                <span
                  className="ui-link"
                  onClick={() => window.Intercom("show")}
                >
                  Talk with an agent
                </span>
                .
              </p>
            </>
          )}
        </Card>
        <video preload="none" autoplay="autoplay" loop="loop" muted>
          <source src={Video} type="video/mp4" />
        </video>
      </div>
    );
  }
}
