import React from "react";
import { Helmet } from "react-helmet";
import Stat from "../../components/generics/Stat";
import Loader from "../../components/generics/Loader";
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  RedditShareButton,
  RedditIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon
} from "react-share";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Table from "../../components/generics/Table";
import moment from "moment";

export default class extends React.Component {
  constructor() {
    super();
    this.state = {
      referral: "",
      referrals: null,
      loading: true
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.property.id !== this.props.property.id) {
      this.setState({ loading: true }, () => this.getData());
    }
  }
  async componentDidMount() {
    this.getData();
  }
  async getData() {
    const referralLink = await this.props.api.get(`/properties/${this.props.property.id}/referrals/link`);
    const referrals = await this.props.api.get(`/properties/${this.props.property.id}/referrals`);
    this.setState({
      referral: referralLink.data.data,
      referrals: referrals.data.data,
      loading: false,
      copied: false
    });
  }
  render() {
    return (
      <div>
        <Helmet>
          <title>Referrals - Green Members</title>
        </Helmet>
        <div className="banner-compact">
          <h1 className="ui-title-mega colour-white">Your Referrals</h1>
          <h2 className="ui-title-four ui-title-sub colour-white margin-top-10 opacity-60 margin-bottom-40">Share and get £10 credit each.</h2>
          <span className="ui-title-four referral-url">
            <a href={this.state.referral} target="_blank">{this.state.referral.replace("https://", "")}<i className="fad fa-external-link margin-left-20"></i></a>
          </span>
          <div className="margin-top-50" style={{ display: "flex", "justifyContent": "center" }}>
            <span className="margin-right-5">
              <CopyToClipboard text={this.state.referral}
                onCopy={() => this.setState({ copied: true })}>
                <div className={`bg-${this.state.copied ? "green" : "orange"} colour-white`} style={{ display: "inline-block", width: 38, height: 38, borderRadius: "50%", fontSize: 18, paddingTop: 8, cursor: "pointer" }}>
                  <i className="far fa-copy"></i>
                </div>
              </CopyToClipboard>
            </span>
            <span className="margin-right-5">
              <EmailShareButton subject="Join Green with my link" body="Visit my unique link and join Green to get free credit:" url={this.state.referral}>
                <EmailIcon size={38} round={true} />
              </EmailShareButton>
            </span>
            <span className="margin-right-5">
              <FacebookShareButton quote="Get free credit when you switch to Green with my unique link!" hashtag="greenenergysupplier" url={this.state.referral}>
                <FacebookIcon size={38} round={true} />
              </FacebookShareButton>
            </span>
            <span className="margin-right-5">
              <TwitterShareButton title="Green - Refer a Friend" hashtags={["greenenergysupplier"]} related={["green"]} url={this.state.referral}>
                <TwitterIcon size={38} round={true} />
              </TwitterShareButton>
            </span>
            <span className="margin-right-5">
              <RedditShareButton title="Join Green with my link" url={this.state.referral}>
                <RedditIcon size={38} round={true} />
              </RedditShareButton>
            </span>
            <span className="margin-right-5">
              <LinkedinShareButton title="Join Green with my link" summary="Visit my unique link and join Green to get free credit!" source="https://green.energy" url={this.state.referral}>
                <LinkedinIcon size={38} round={true} />
              </LinkedinShareButton>
            </span>
            <WhatsappShareButton title="Join Green with my link:" url={this.state.referral}>
              <WhatsappIcon size={38} round={true} />
            </WhatsappShareButton>
          </div>
        </div>
        <div className="padding-50">
          {this.state.loading ? (
            <div className="padding-50 grid-center-center">
              <Loader />
            </div>
          ) : <>
            <div className={`col-3`}>
              <Stat
                stat={this.state.referrals === null ? "" : this.state.referrals.length}
                statColour="blue"
                title="Total Referrals"
                subTitle="All pending &amp; live"
              />
              <Stat
                stat={this.state.referrals === null ? "" : `£${(this.state.referrals.filter(x => x.status === "Pending").length * 10).toFixed(2)}`}
                statColour="olive"
                title="Pending Credit"
                subTitle="On it's way"
              />
              <Stat
                stat={this.state.referrals === null ? "" : `£${(this.state.referrals.filter(x => x.status === "Active").length * 10).toFixed(2)}`}
                statColour="green"
                title="Credit Added"
                subTitle="Reflected on your balance"
              />
            </div>
            <div className="container-small">
              <div className="col-1 gap-80 col-start-2">
                <div />
                <div>
                  <h2 className="ui-title-one text-center">Your Referral Breakdown</h2>
                  <h3 className="ui-title-four ui-title-sub text-center margin-top-10">See all pending and live referrals.</h3>
                </div>
                {
                  this.state.referrals === null ? null : this.state.referrals.length ? <>
                    <Table data={this.state.referrals.map(x => ({
                      "Referral Date": moment(x.saleDate).format("DD/MM/YYYY HH:mm"),
                      "Status": <>
                      <i style={{ marginRight: 10, color: x.status === "Pending" ? "orange" : "#30AF5F"}} className={`fa fa-circle`} />
                      {x.status}
                      </>
                    }))} />
                  </> : <p className="ui-label text-center">No referrals just yet - get sharing and see your credit mount up!</p>
                }
                <div />
              </div>
            </div></>}
        </div>
      </div>
    );
  }
}