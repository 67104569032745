import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import ScrollToTop from "react-router-scroll-top";
import Home from "../views/members/Home";
import Readings from "../views/members/Readings";
import Payments from "../views/members/Payments";
import Account from "../views/members/Account";
import Referrals from "../views/members/Referrals";
import Support from "../views/members/Support";

const Routes = props => (
  <ScrollToTop><Switch>
    {/* <Route
      path="/members/home"
      render={internalProps => <Home {...internalProps} {...props} />}
    />
    <Route
      path="/members/readings"
      render={internalProps => <Readings {...internalProps} {...props} />}
    /> */}
    <Route
      path="/members/payments"
      render={internalProps => <Payments {...internalProps} {...props} />}
    />
    {/* <Route
      path="/members/account"
      render={internalProps => <Account {...internalProps} {...props} />}
    /> */}
     {/* <Route
      path="/members/referrals"
      render={internalProps => <Referrals {...internalProps} {...props} />}
    /> */}
     {/* <Route
      path="/members/support"
      render={internalProps => <Support {...internalProps} {...props} />}
    /> */}
    <Route render={() => <Redirect to="/members/payments" />} />
  </Switch></ScrollToTop>
);

export default Routes;
