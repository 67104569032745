import React, { useState, useEffect } from "react";
import {
    CardElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";

export default function TopUpForm({ api, property, amount, onSuccess }) {
    const [succeeded, setSucceeded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState('');
    const [disabled, setDisabled] = useState(true);
    const stripe = useStripe();
    const elements = useElements();
    const handleChange = async (event) => {
        setDisabled(event.empty);
        setError(event.error ? event.error.message : "");
    };
    const handleSubmit = async ev => {
        ev.preventDefault();
        setProcessing(true);
        try {
            const intent = await api.post(`/payments/intent`, {
                propertyId: property.id,
                amount: amount * 100
            });
            console.log(intent.data);
            const payload = await stripe.confirmCardPayment(intent.data.data.secret, {
                payment_method: {
                    card: elements.getElement(CardElement)
                }
            });
            if (payload.error) {
                setError(`Payment failed: ${payload.error.message}`);
                setProcessing(false);
            } else {
                setError(null);
                setProcessing(false);
                onSuccess();
            }
        } catch (e) {
            if(!e.response){
                console.log(e);
            }
            setError(`Payment failed: ${e.response.data.errors}`);
            setProcessing(false);
        }
    };
    return (
        <form className="topup-form" onSubmit={handleSubmit}>
            <CardElement id="card-element" onChange={handleChange} />
            <button
                disabled={processing || disabled || succeeded || !amount}
                id="submit"
                className="ui-btn-secondary ui-btn-flat"
            >
                <span id="button-text">
                    {processing ? (
                        <div className="spinner" id="spinner"></div>
                    ) : (
                            `Pay £${amount.toFixed(2)}`
                        )}
                </span>
            </button>
            {error && (
                <div className="card-error" role="alert">
                    {error}
                </div>
            )}
        </form>
    );
}